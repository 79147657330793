<template>
  <div>
    <b-form class="form" v-on:submit.prevent="onSubmit">
      <b-form-group label-cols-lg="2" label="Date">
        <b-form-input disabled :value="date"></b-form-input>
      </b-form-group>
      <b-form-group
        id="remark"
        label-for="remark"
        :label="$t('JOB.REMARK')"
        label-cols-lg="2"
      >
        <b-form-textarea
          id="remark"
          v-model="$v.form.remark.$model"
          :placeholder="$t('JOB.REMARK1')"
          :state="validateState('remark')"
        ></b-form-textarea>

        <b-form-invalid-feedback v-if="!$v.form.remark.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <div class="form-group d-flex justify-content-end">
        <button
          type="submit"
          ref="department-form-submit"
          class="btn btn-primary font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  name: "PersonalAwayEntitlementApplyAwayForm",
  mixins: [validationMixin],
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        remark: "",
      },
      date: dayjs().format("D/M/YYYY"),
      formBusy: false,
      mainProps: { blank: true, width: 200, height: 200, class: "m1" },
    };
  },
  validations: {
    form: {
      days: {},
      remark: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Application Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$emit("submit-clicked", this.form);
    },
  },
};
</script>

<style scoped></style>
